<template>
  <b-card>
    <a href="https://docs.google.com/spreadsheets/d/1Gdxge5z8JpkiKTlJAVLP4t2hvHWpHpFmN5GVcK0VkL4" target="_blank">스마트스토어 Sheet</a><br/>
    <a href="https://sell.smartstore.naver.com/#/home/dashboard" target="_blank">스마트스토어센터</a><br/>
    <a href="https://partner.lotteimall.com/main/Login.lotte" target="_blank">lotteimall partner</a><br/>
    <a href="http://proxy-hub.balaan.io:53535/proxy/alive?type=list" target="_blank">Proxy 보기</a><br/>
    <a href="https://cloud.mongodb.com/freemonitoring/cluster/SJJUV2JL7D2X2LW242CCLKIWMYDIC3DV" target="_blank">몽고DB 모니터링</a><br/>
    <a href="https://github.com/search?l=&q=user%3Abalaan-team1+" target="_blank">깃헙 팀 검색</a><br/>
    <a href="https://github.com/balaan-team1/hub/compare/master@%7B2%20day%7D...master@%7B0%20day%7D" target="_blank">깃헙 commit 사이 비교</a><br/>
    <a href="https://join.shopping.naver.com/faq/list.nhn?catgCd=H00015" target="_blank">네이버 쇼핑상품 검색 알고리즘</a><br/>
    <a href="http://stat.balaan.io:3000/" target="_blank">메타베이스(kr 대시보드)</a><br/>
  </b-card>
</template>

<script>
import {postFeed, getJson, postJson} from '@/shared/api'

export default {
  name: 'Link',
  title: '링크모음',
  data() {
    return {
    }
  },
}
</script>
